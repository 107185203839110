export class Profile {

	saveBlock(parent)
	{
		let _this = this;
		let form = $(parent);
		let button = form.find('button[type=submit]');
		button.attr('disabled', true);

		if (form.find('input[name=block_type]').val() == 'email')
		{
			let input = form.find('input[name=str_email]');
			if (input.val() != input.attr('data-original'))
			{
				$(".popup-email").removeClass("active");
				$(".popup-email-edit").addClass("active");

				this.requestConfirm('phone');
				button.attr('disabled', false);
				return;
			}
		}

		if (form.find('input[name=block_type]').val() == 'phone')
		{
			let input = form.find('input[name=str_phone]');
			if (input.val() != input.attr('data-original'))
			{
				$(".popup-tel").removeClass("active");
				$(".popup-tel-edit").addClass("active");

				this.requestConfirm('email');
				button.attr('disabled', false);
				return;
			}
		}
		console.log(form.serialize())
		$.ajax({
			url: "/ajax.php?mid=21",
			type: 'POST',
			data: form.serialize() + '&action=saveBlock',
			dataType: "json",
			success: function(res){
				button.attr('disabled', false);

				if (res['success'])
				{
					_this.hideMistake(form);
					$('.profile-data__content').replaceWith(res['html']);
					_this.applyEvents();
					_this.hidePopup(form.closest('.popup'));
				}
				else
				{
					_this.showMistake(form, 'Ошибка: ' + res['error']);

					if (res['activate_link'])
					{
						$('#auth_block .form__mistakes').append('<p><a href="' + res['activate_link'] + '" target="_blank">Выслать код активации повторно</a></p>');
					}
				}
			},
			error: function(res){
				button.attr('disabled', false);
				_this.showMistake(form, 'Ошибка отправки данных. Пожалуйста, попробуйте ещё раз или обратитесь к администратору сайта');
			}
		});
	}

	changePwd(parent)
	{
		let _this = this;
		let form = $(parent);
		let button = form.find('button[type=submit]');
		button.attr('disabled', true);

		$.ajax({
			url: "/ajax.php?mid=21",
			type: 'POST',
			data: form.serialize() + '&action=changePwd',
			dataType: "json",
			success: function(res){
				button.attr('disabled', false);

				if (res['success'])
				{
					_this.hidePopup(form.closest('.popup'));
				}
				else
				{
					_this.showMistake(form, 'Ошибка: ' + res['error']);
				}
			},
			error: function(res){
				button.attr('disabled', false);
				_this.showMistake(form, 'Ошибка отправки данных. Пожалуйста, попробуйте ещё раз или обратитесь к администратору сайта');
			}
		});
	}

	deleteAccount(parent)
	{
		let _this = this;
		let button = $(parent);
		button.attr('disabled', true);

		$.ajax({
			url: "/ajax.php?mid=21",
			type: 'POST',
			data: {action: 'deleteAccount'},
			dataType: "json",
			success: function(res){
				button.attr('disabled', false);

				if (res['success'])
				{
					$('.profile-data__content').replaceWith(res['html']);
					_this.applyEvents();

					_this.hidePopup(button.closest('.popup'));
				}
				else
				{
					//_this.showMistake(form, 'Ошибка: ' + res['error']);
				}
			},
			error: function(res){
				button.attr('disabled', false);
				//_this.showMistake(form, 'Ошибка отправки данных. Пожалуйста, попробуйте ещё раз или обратитесь к администратору сайта');
			}
		});
	}

	cancelDeleteAccount(parent)
	{
		let _this = this;
		let button = $(parent);
		button.attr('disabled', true);

		$.ajax({
			url: "/ajax.php?mid=21",
			type: 'POST',
			data: {action: 'cancelDeleteAccount'},
			dataType: "json",
			success: function(res){
				button.attr('disabled', false);

				if (res['success'])
				{
					$('.profile-data__content').replaceWith(res['html']);
					_this.applyEvents();

					_this.hidePopup(button.closest('.popup'));
				}
				else
				{
					//_this.showMistake(form, 'Ошибка: ' + res['error']);
				}
			},
			error: function(res){
				button.attr('disabled', false);
				//_this.showMistake(form, 'Ошибка отправки данных. Пожалуйста, попробуйте ещё раз или обратитесь к администратору сайта');
			}
		});
	}

	requestConfirm(type)
	{
		let _this = this;

		$.ajax({
			url: "/ajax.php?mid=21",
			type: 'POST',
			data: {action: 'requestConfirm', type: type},
			dataType: "json",
			success: function(res){

			},
			error: function(res){

			}
		});
	}

	showMistake(block, text)
	{
		$(block).find('.form__mistakes p').text(text);
		$(block).find('.form__mistakes').show();
	}

	hideMistake(block)
	{
		$(block).find('.form__mistakes p').text('');
		$(block).find('.form__mistakes').hide();
	}

	hidePopup(popup)
	{
		$("body").removeClass("no-scroll");
		popup.removeClass("active");
	}

	applyEvents()
	{

		$(".data-card__edit--personal").click(function (e){
			$("body").addClass("no-scroll");
			$(".popup-personal").addClass("active");
		});

		$(".data-card__edit--tel").click(function (e){
			$("body").addClass("no-scroll");
			$(".popup-tel").addClass("active");
		});

		$(".data-card__edit--email").click(function (e){
			$("body").addClass("no-scroll");
			$(".popup-email").addClass("active");
		});

		$(".data-card__edit--password").click(function (e){
			$("body").addClass("no-scroll");
			$(".popup-password").addClass("active");
		});

		$(".data-card__edit--delete").click(function (e){
			$("body").addClass("no-scroll");
			$(".popup-delete").addClass("active");
		});

		$(".popup-login__to-recover").click(function (e){
			$(".popup-login").removeClass("active");
			$(".popup-recover").addClass("active");
		});

		$(".popup-password__to-recover").click(function (e){
			$(".popup-password").removeClass("active");
			$(".popup-recover").addClass("active");
		});
	}

}
