export class Catalog {

	buyItem(id)
	{
		let _this = this;
		$('#buy_button').prop('disabled', true);

		$.ajax({
			url: "/ajax.php?mid=5",
			type: 'POST',
			data: {
				action: 'buy_item',
				id : id
				},
			dataType: "json",
			success: function(res){
				if (res.success)
				{
					$('.buy_step_1').hide();
					$('.buy_step_2').fadeIn();

					$('.buy_step_2').attr('data-buy_id', res.buy_id);

					_this.startTimer(res.remain);
				}
				else
				{
					alert('Ошибка: ' + res.error);
				}
			},
			error: function(res){
				$('#buy_button').prop('disabled', false);
				alert('Ошибка: ' + res);
			}
		});
	}

	startTimer(remain)
	{
		let _this = this;
		$('.goods-info__timer-main').removeClass('goods-info__timer-main--remaining');

		let time = remain;
		$('.goods-info__timer-value').text(_this.formatMinutes(time));

		this.intervalId = setInterval(function(){
			time--;

			$('.goods-info__timer-value').text(_this.formatMinutes(time));
			if (time < 300)
			{
				$('.goods-info__timer-main').addClass('goods-info__timer-main--remaining');
			}

			if (time == 0)
			{
				clearInterval(_this.intervalId);
				$('.goods-info__timer-main').hide();
				$('.goods-info__timer-over').fadeIn();
			}
		}, 1000);
	}

	stopTimer()
	{
		clearInterval(this.intervalId);
	}

	formatMinutes(time)
	{
		let minutes = Math.floor(time / 60);
		let seconds = time - minutes * 60;
		if (seconds < 10) seconds = '0' + seconds;

		return minutes + ':' + seconds;
	}

	cancelBuy(id)
	{
		let _this = this;
		if (typeof id != 'number')
			id = $(id).closest('.buy_step_2').attr('data-buy_id');

		$('#buy_button').prop('disabled', false);

		$.ajax({
			url: "/ajax.php?mid=5",
			type: 'POST',
			data: {
				action: 'cancel_buy',
				id : id
				},
			dataType: "json",
			success: function(res){
				if (res.success)
				{
					$('.buy_step_2').hide();
					$('.buy_step_1').fadeIn();

					$('.buy_step_2').attr('data-buy_id', '');

					_this.stopTimer();
				}
				else
				{
					alert('Ошибка: ' + res.error);
				}
			},
			error: function(res){
				$('#buy_button').prop('disabled', false);
				alert('Ошибка: ' + res);
			}
		});
	}

	confirmBuy(id)
	{
		let _this = this;
		if (typeof id != 'number')
			id = $(id).closest('.buy_step_2').attr('data-buy_id');

		$.ajax({
			url: "/ajax.php?mid=5",
			type: 'POST',
			data: {
				action: 'confirm_buy',
				id : id
				},
			dataType: "json",
			success: function(res){
				if (res.success)
				{
					location.href = '/mycart/' + id + '/';
				}
				else
				{
					alert('Ошибка: ' + res.error);
				}
			},
			error: function(res){
				alert('Ошибка: ' + res);
			}
		});
	}

	applyEvents()
	{
		if ($('.buy_step_2').attr('data-buy_id'))
		{
			this.startTimer($('.buy_step_2').attr('data-remain'));
		}
		
	}
}

