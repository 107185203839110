export class Order {

	ajaxAuth()
	{
		$.ajax({
			url: "/ajax.php?mid=18",
			type: 'POST',
			data: {
				action: 'login',
				login : $('#auth_1_login').val(),
				password : $('#auth_1_pass').val(),
				auth_1_submit : 1,
				auth_1_remember : 1
				},
			dataType: "json",
			success: function(res){
				$('#auth_submit').attr('disabled', false);

				if (res['success'])
				{
					location.reload();
				}
				else
				{
					$('.popup-login .form__mistakes p').text('Ошибка: ' + res['error']);
					if (res['activate_link'])
					{
						$('.header__login-mistakes').append('<p><a href="' + res['activate_link'] + '" target="_blank">Выслать код активации повторно</a></p>');
					}
				}
			},
			error: function(res){
				$('#auth_popup input').attr('disabled', false);
				$('#auth_popup .error').text('Ошибка отправки данных. Пожалуйста, попробуйте ещё раз или обратитесь к администратору сайта');
			}
		});
	}

	sendOrderNum(id)
	{
		let _this = this;
		let orderNum = $('#order_num').val();

		$.ajax({
			url: "/ajax.php?mid=5",
			type: 'POST',
			data: {
				action: 'send_order_num',
				id : id,
				order_num : orderNum
				},
			dataType: "json",
			success: function(res){
				if (res.success)
				{
					$('.process-confirmation__form-main').hide();
					$('.process-confirmation__form-success').fadeIn();
					//location.reload();
				}
				else
				{
					$('.process-confirmation__form-mistakes p').text(res.error);
				}
			},
			error: function(res){
				alert('Ошибка: ' + res);
			}
		});
	}

	confirmDelivery(id)
	{
		let _this = this;

		$.ajax({
			url: "/ajax.php?mid=5",
			type: 'POST',
			data: {
				action: 'confirm_delivery',
				id : id
				},
			dataType: "json",
			success: function(res){
				if (res.success)
				{
					$('.process-delivery__main').hide();
					$('.process-delivery__success').fadeIn();
					//location.reload();
				}
				else
				{
					$('.process-confirmation__form-mistakes p').text(res.error);
				}
			},
			error: function(res){
				alert('Ошибка: ' + res);
			}
		});
	}

	sendReview(id)
	{
		let _this = this;

		$.ajax({
			url: "/ajax.php?mid=5",
			type: 'POST',
			data: {
				action: 'send_review',
				id : id
				},
			dataType: "json",
			success: function(res){
				if (res.success)
				{
					$('.process-review__form-main').hide();
					$('.process-review__form-success').fadeIn();
					//location.reload();
				}
				else
				{
					$('.process-review__form-mistakes p').text(res.error);
				}
			},
			error: function(res){
				alert('Ошибка: ' + res);
			}
		});
	}

	reloadOrder()
	{
		let id = $('.order-summary').attr('data-id');
		let _this = this;

		$.ajax({
			url: "/ajax.php?mid=5",
			type: 'POST',
			data: {
				action: 'load_order',
				id : id
				},
			dataType: "html",
			success: function(res){
				$('.order-page').replaceWith(res);
			},
			error: function(res){
				console.log(res);
				alert(res);
			}
		});
	}


}

