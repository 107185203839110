import { Auth } from "../Auth/Auth.js";
const auth = new Auth();
window.auth = auth;

import { Catalog } from "../Catalog/Catalog.js";
const catalog = new Catalog();
window.catalog = catalog;

import { Order } from "../Profile/Order.js";
const order = new Order();
window.order = order;

import { Profile } from "../Profile/Profile.js";
const profile = new Profile();
window.profile = profile;

// Resize
$(window).resize(function() {
	setTypeDevice();
});

// Document clicks
$(document).click(function(e) {
	if ($(".user-profile__popup").is(":visible")) {
		var callbackPopup = $(".user-profile")
		if (!callbackPopup.is(e.target) && callbackPopup.has(e.target).length === 0) {
			$('.user-profile__popup').fadeOut();
		};
	}
});

// Type of device
function isTouchDevice() {
	return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
}

function setTypeDevice() {
	if (isTouchDevice()) {
		$('body').removeClass('des');
		$('body').addClass('mob');
	} else {
		$('body').removeClass('mob');
		$('body').addClass('des');
	}
}
setTypeDevice();

// Header sticky
var lastSrollTop = 0;
var header = document.querySelector(".header");
var headerTop = document.querySelector(".header__top");

window.addEventListener("scroll", function(){
	var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
	var headerTopHeight = headerTop.clientHeight;
	if (scrollTop > 0){
		header.classList.add("scrolled");
	} else {
		header.classList.remove("scrolled");
	}

	if (scrollTop > lastSrollTop){
		header.style.top = "-100%";
	} else {
		header.style.top = "-" + headerTopHeight + "px";
	}
	lastSrollTop = scrollTop;
})

// user profile popup
$('.mob .user-profile__link').click(function(e){
	e.preventDefault();
	$('.user-profile__popup').fadeToggle();
});

// Tabs
$(".tabs__tab:not(:first-of-type)").hide();
$(".tabs__nav-item:first-of-type .tabs__nav-btn").addClass("active");
$(".tabs__nav-btn").click(function() {
	if(!$(this).hasClass('active')) {
		$(this).parent(".tabs__nav-item").siblings().children(".tabs__nav-btn").removeClass("active");
		$(this).addClass("active");
		$(this).parents(".tabs").find(".tabs__tab").hide();
		$(".tabs__tab").eq($(this).index(".tabs__nav-btn")).fadeIn();
	}
});

// Sliders
var catalogSlider = new Swiper('.catalog-slider', {
	slidesPerView: 'auto',
	watchOverflow: true,
	spaceBetween: 8,
	navigation: {
		nextEl: '.catalog-slider__nav--next',
		prevEl: '.catalog-slider__nav--prev'
	},
	breakpoints: {
		768: {
			spaceBetween: 16
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 16
		},
		1280: {
			slidesPerView: 4,
			spaceBetween: 16
		},
		1440: {
			slidesPerView: 4,
			spaceBetween: 24
		}
	}
});

var reviewsSlider = new Swiper('.reviews-slider', {
	slidesPerView: 'auto',
	watchOverflow: true,
	spaceBetween: 16,
	navigation: {
		nextEl: '.reviews-slider__nav--next',
		prevEl: '.reviews-slider__nav--prev'
	},
	breakpoints: {
		1024: {
			slidesPerView: 2,
		},
		1280: {
			slidesPerView: 3,
		},
		1440: {
			slidesPerView: 3,
			spaceBetween: 24
		}
	}
});

var swiperGoodsPageThumbs = new Swiper('.goods-page__thumbs-wrapper', {
	centeredSlides: true,
	centeredSlidesBounds: true,
	slidesPerView: 3,
	spaceBetween: 8,
	watchOverflow: true,
	watchSlidesProgress: true,
	navigation: {
		nextEl: '.goods-page__thumbs-nav--next',
		prevEl: '.goods-page__thumbs-nav--prev'
	},
	breakpoints: {
		1024: {
			direction: 'vertical'
		}
	}
});

var swiperGoodsPageGallery = new Swiper('.goods-page__slider-wrapper', {
	slidesPerView: 'auto',
	watchOverflow: true,
	watchSlidesProgress: true,
	navigation: {
		nextEl: '.goods-page__slider-nav--next',
		prevEl: '.goods-page__slider-nav--prev'
	},
	thumbs: {
		swiper: swiperGoodsPageThumbs
	}
});

// Catalog filters
$(".catalog-basic__filter-toggler").click(function() {
	$("body").addClass("no-scroll");
	$(".catalog-basic__filters").addClass("active");
});

$(".catalog-filters__close").click(function() {
	$("body").removeClass("no-scroll");
	$(".catalog-basic__filters").removeClass("active");
});

// Popup
$(".popup__close, .popup__btn-close").click(function(e){
	$("body").removeClass("no-scroll");
	$(this).closest(".popup").removeClass("active");
});

$(".popup").click(function(e) {
	var callbackPopup = $(".popup__container")
	if (!callbackPopup.is(e.target) && callbackPopup.has(e.target).length === 0) {
		$(this).removeClass("active");
		$("body").removeClass("no-scroll");
	};
});

$(".user-signin__button, .auth-form__login-btn").click(function (e){
	$("body").addClass("no-scroll");
	$(".popup-login").addClass("active");
});

profile.applyEvents();

// Orders notifications
$(".orders-notifications__close").click(function() {
	$(".orders-notifications").fadeOut();
});

// Help QA
$(".help-qa__table-question").click(function() {
	$(".help-qa__table-question").not(this).removeClass("active");
	$(".help-qa__table-question").not(this).siblings(".help-qa__table-answer").slideUp();
	$(this).toggleClass("active")
	$(this).siblings(".help-qa__table-answer").slideToggle();
});

// Instructions
$(".instructions-stage__toggler").click(function() {
	$(".instructions-stage__toggler").not(this).removeClass("active");
	$(".instructions-stage__toggler").not(this).siblings(".instructions-stage__steps").slideUp();
	$(this).toggleClass("active")
	$(this).siblings(".instructions-stage__steps").slideToggle();
});

