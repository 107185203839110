export class Auth {

	ajaxAuth()
	{
		$.ajax({
			url: "/ajax.php?mid=18",
			type: 'POST',
			data: {
				action: 'login',
				login : $('#auth_1_login').val(),
				password : $('#auth_1_pass').val(),
				auth_1_submit : 1,
				auth_1_remember : 1
				},
			dataType: "json",
			success: function(res){
				$('#auth_submit').attr('disabled', false);

				if (res['success'])
				{
					location.reload();
				}
				else
				{
					$('.popup-login .form__mistakes p').text('Ошибка: ' + res['error']);
					if (res['activate_link'])
					{
						$('.header__login-mistakes').append('<p><a href="' + res['activate_link'] + '" target="_blank">Выслать код активации повторно</a></p>');
					}
				}
			},
			error: function(res){
				$('#auth_popup input').attr('disabled', false);
				$('#auth_popup .error').text('Ошибка отправки данных. Пожалуйста, попробуйте ещё раз или обратитесь к администратору сайта');
			}
		});
	}

	startTimer()
	{
		$('.auth-form__repeat').fadeIn();
		let time = 59;
		let intervalId = setInterval(function(){
			time--;
			$('.auth-form__repeat-timer').text('00:' + time);
			if (time == 0)
			{
				clearInterval(intervalId);
				$('.auth-form__repeat').hide();
				$('.auth-form__tel-code-btn').prop('disabled', false);
			}
		}, 1000);
	}

	regPhone()
	{
		$('.auth-form__tel-code-btn').prop('disabled', true);
		let _this = this;

		$.ajax({
			url: "/ajax.php?mid=19",
			type: 'POST',
			data: {
				action: 'reg_phone',
				str_phone : $('.auth-form__tel-select').val() + $('.auth-form__tel-input').val()
				},
			dataType: "json",
			success: function(res){
				$('.auth-form__tel-code-input').fadeIn();
				_this.startTimer();
			},
			error: function(res){
				$('.auth-form__tel-code-btn').prop('disabled', false);
				console.log(res);
				alert(res);
			}
		});
	}

	submitCode()
	{
		$('#sms_code_submit').prop('disabled', true);
		let _this = this;

		$.ajax({
			url: "/ajax.php?mid=19",
			type: 'POST',
			data: {
				action: 'check_code',
				code : $('.auth-form__tel-code-input').val()
				},
			dataType: "json",
			success: function(res){
				if (res.success)
				{
					$('.form__mistakes p').text('');
					$('#reg_user_submit').prop('disabled', false);
					$('#reg_step_2').hide();
					$('#reg_step_3').fadeIn();
				}
				else
				{
					$('#reg_step_2 .form__mistakes p').text(res.error);
				}
			},
			error: function(res){
				$('#sms_code_submit').prop('disabled', false);
				console.log(res);
				alert(res);
			}
		});
	}

	reg_user()
	{
		$('#reg_user_submit').prop('disabled', true);
		let _this = this;

		$.ajax({
			url: "/ajax.php?mid=19",
			type: 'POST',
			data: $('#reg_step_3 input').serialize() + '&action=reg_user',
			dataType: "json",
			success: function(res){
				if (res.success)
				{
					$('#reg_step_3 .form__mistakes p').text('');
					$('#reg_step_3').hide();
					//$('#reg_step_3').fadeIn();
					location.replace('/catalog/');
				}
				else
				{
					$('#reg_step_3 .form__mistakes p').text(res.error);
				}
			},
			error: function(res){
				$('#reg_user_submit').prop('disabled', false);
				console.log(res);
				alert(res);
			}
		});
	}

	smsCodeInput(parent)
	{
		let code = $(parent).val();
		let digits = parseInt(code).toString().length;
		if (digits == 5)
		{
			$('#sms_code_submit').prop('disabled', false);
		}
		else
		{
			$('#sms_code_submit').prop('disabled', true);
		}
	}

	selectRegType()
	{
		$('#choose_type_submit').prop('disabled', false);
	}

	step2()
	{
		$('#reg_step_1').hide();
		$('#reg_step_2').fadeIn();
	}

	applyEvents()
	{
		let _this = this
		$('.header__login-form').submit(function(){
			_this.ajaxAuth();
			return false;
		});
	}

}

